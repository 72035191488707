import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PaymentState } from './payment.models';
import { PAYMENT_FEATURE_KEY, paymentAdapter } from './payment.reducer';

// Lookup the 'Payment' feature state managed by NgRx
export const selectPaymentState = createFeatureSelector<PaymentState>(PAYMENT_FEATURE_KEY);

const { selectAll, selectEntities } = paymentAdapter.getSelectors();

export const selectPaymentLoaded = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.loaded,
);

export const selectPaymentFetchError = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.error,
);

export const selectAllPayment = createSelector(selectPaymentState, (state: PaymentState) =>
  selectAll(state),
);

export const selectPaymentEntities = createSelector(selectPaymentState, (state: PaymentState) =>
  selectEntities(state),
);

export const selectPaymentStateData = createSelector(
  selectPaymentState,
  (state: PaymentState) => state,
);

export const selectSelectedId = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.selectedId,
);

export const selectEntity = createSelector(
  selectPaymentEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined),
);

export const selectCheckoutInfo = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.checkoutInfo,
);

export const selectPublicKey = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.publicKey,
);

export const selectAddressBookId = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.addressBookId,
);

export const selectReturnUrl = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.returnUrl,
);

export const selectPaymentSuccess = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.paymentSuccess,
);

export const selectPaymentError = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.paymentError,
);

export const selectStripeCardToken = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.stripeCardToken,
);

export const selectPaymentBusinessId = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.businessId,
);
