import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap, of, shareReplay, switchMap, withLatestFrom } from 'rxjs';

import { ErpSideService, PaymentService } from '@offconon/core-api';

import * as PaymentActions from './payment.actions';
import { PaymentState } from './payment.models';
import { selectPaymentBusinessId } from './payment.selectors';

@Injectable()
export class PaymentEffects {
  private actions$ = inject(Actions);
  private paymentService = inject(PaymentService);
  private erpSideService = inject(ErpSideService);
  private store$ = inject(Store<PaymentState>);

  loadCheckoutInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentActions.fetchPaymentSubscriptionCheckoutRetrieve),

        mergeMap(() =>
          this.paymentService.paymentSubscriptionCheckoutRetrieve().pipe(
            switchMap((checkoutInfo) =>
              of(
                PaymentActions.setLoaded({ loaded: false }),
                PaymentActions.loadCheckoutInfo({ checkoutInfo }),
                PaymentActions.setLoaded({ loaded: true }),
              ),
            ),
            catchError((error) => of(PaymentActions.loadPaymentFailure({ error }))),
            shareReplay(1),
          ),
        ),
      ),
    { dispatch: true } as any,
  );

  loadErpSideSubscriptionCheckout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentActions.fetchErpSideSubscriptionCheckout),
        withLatestFrom(this.store$.select(selectPaymentBusinessId)),

        mergeMap((results: any) => {
          return this.erpSideService.erpSideSubscriptionCheckoutRetrieve(results[1]).pipe(
            switchMap((checkoutInfo) =>
              of(
                PaymentActions.setLoaded({ loaded: false }),
                PaymentActions.loadCheckoutInfo({ checkoutInfo }),
                PaymentActions.setLoaded({ loaded: true }),
              ),
            ),
            catchError((error) => of(PaymentActions.loadPaymentFailure({ error }))),
          );
        }),
      ),

    { dispatch: true } as any,
  );
}
