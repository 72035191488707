import { createAction, props } from '@ngrx/store';

import { SubscriptionCheckoutAnswer } from '@offconon/core-api';

import { PaymentEntity } from './payment.models';

export const initPayment = createAction('[Payment Page] Init');

export const loadPaymentSuccess = createAction(
  '[Payment/API] Load Payment Success',
  props<{ payment: PaymentEntity[] }>(),
);

export const setLoaded = createAction('[Payment/API] Set Loaded', props<{ loaded: boolean }>());

export const loadPaymentFailure = createAction(
  '[Payment/API] Load Payment Failure',
  props<{ error: any }>(),
);

export const fetchPaymentSubscriptionCheckoutRetrieve = createAction(
  '[Payment/API] Fetch Payment Subscription Checkout Retrieve',
);

export const loadCheckoutInfo = createAction(
  '[Payment/API] Load Checkout Info',

  props<{ checkoutInfo: SubscriptionCheckoutAnswer }>(),
);

export const setPublicKey = createAction(
  '[Payment/API] Set Public Key',
  props<{ publicKey: string }>(),
);

export const setAddressBookId = createAction(
  '[Payment/API] Set Address Book Id',
  props<{ addressBookId: number }>(),
);

export const setReturnUrl = createAction(
  '[Payment/API] Set Return Url',
  props<{ returnUrl: string }>(),
);

export const setPaymentSuccess = createAction(
  '[Payment/API] Set Payment Success',
  props<{ paymentSuccess: any }>(),
);

export const setPaymentError = createAction(
  '[Payment/API] Set Payment Error',
  props<{ paymentError: any }>(),
);

export const fetchErpSideSubscriptionCheckout = createAction(
  '[Payment/API] Fetch Erp Side Subscription Checkout',
);

export const setPaymentBusinessId = createAction(
  '[Payment/API] Set Business Id',
  props<{ businessId: number }>(),
);

// export const createWalletBalanceUploadPaymentIntent = createAction(
//   '[Payment/API] Create Wallet Balance Upload Payment Intent',

//   props<{ walletParameters: UploadWallletParametersSerializersRequest }>(),
// );

export const payWithOneClick = createAction(
  '[Payment/API] Pay With One Click',
  props<{ paymentType: 'stripe_link_pay' | 'stripe_apple_pay' | 'stripe_google_pay' }>(),
);
export const payWithOneClickSuccess = createAction(
  '[Payment/API] Pay With One Click Success',
  props<{ paymentSuccess: any }>(),
);
