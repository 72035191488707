import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PaymentEffects } from './store/payment.effects';
import * as fromPayment from './store/payment.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromPayment.PAYMENT_FEATURE_KEY, fromPayment.paymentReducer),
    EffectsModule.forFeature([PaymentEffects]),
  ],
})
export class SharedDataAccessPaymentModule {}
